<template>
  <div class="chosen-filter px-4 py-2">
    <span class="filter-title px-4">stai filtrando per:</span>
    <filter-chip filterType="query" :filterValue="queryParam" ></filter-chip>
    <filter-chip filterType="topics" :filterValue="topicsParam" ></filter-chip>
    <filter-chip filterType="tags" :filterValue="tagsParam" ></filter-chip>
    <filter-chip filterType="owners" :filterValue="ownersParam" ></filter-chip>
  </div>
</template>

<script>
import FilterChip from "./FilterChip";
export default {
  name: "DatasetChosenFilter",
  components: {
    FilterChip,
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    queryParam() {
      return this.searchParams?.query ?? "";
    },
    topicsParam() {
      return this.searchParams?.topics[0] ?? "";
    },
    tagsParam() {
      return this.searchParams?.tags[0] ?? "";
    },
    ownersParam() {
      return this.searchParams?.owners[0] ?? "";
    },
  },
};
</script>

<style scoped lang="scss">
.chosen-filter {
  background-color: #e6feff;
  border-radius: 20px;
  .filter-title {
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
