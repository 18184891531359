<template>
  <v-card width="100%">
    <v-row class="justify-space-between">
      <v-col class="col-12 col-md-10">
        <!-- title -->
        <v-card-text class="card-title" @click="goToDetail">
          <h2>{{ title }}
          <v-icon color="#000000" class="ml-2 launch-icon">launch</v-icon>
          </h2>
        </v-card-text>

        <v-card-text class="pt-1">
          <!-- topics -->
          <v-row>
            <div class="pl-3" v-for="topic in topics" :key="'topic' + topic">
              <topic-chip :topic="topic"></topic-chip>
            </div>
          </v-row>

          <!-- description -->
          <p class="dataset-description">{{ displayDescription }}</p>

          <!-- info -->
          <p class="publisher-info mb-0">
            Ente titolare:
            <span class="body-bold">{{ owner }}</span>
          </p>
          <p class="publisher-info mb-0">
            Fonte:
            <span class="body-bold">{{ fonte }}</span>
          </p>
          <p class="publisher-info mb-0">
            Ultimo aggiornamento scheda metadato:
            <span class="body-bold">{{ metadataModified | numbDate }}</span>
          </p>

          <!-- tags -->
          <v-chip
            v-for="tag in displayTags"
            :key="'tag' + tag"
            class="mr-2 mt-4 tag-chip"
            color="#2AB5B9"
            outlined
          >
            <span class="tag-text">{{ tag.toLowerCase() }}</span>
          </v-chip>
        </v-card-text>
      </v-col>

      <!-- download csv button -->
      <v-col class="col-md-2">
        <v-row class="justify-center">
          <v-btn v-if="csvHref != ''" color="#008000" :href="csvHref">
            <v-icon class="csv-icon" color="#FFFFFF">save_alt</v-icon>
            <span class="csv-text ml-1">
              <span class="sr-only">{{ title }} - scarica </span>
              CSV
            </span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TopicChip from "./TopicChip";
import { CATALOGO_DETAIL } from "../router/routes";
import { SOURCE_LIST } from "../boot/globals";
export default {
  name: "DatasetResultCard",
  props: {
    dataset: { type: Object, required: false },
  },
  components: {
    TopicChip,
  },
  data() {
    return {
      links: [],
    };
  },
  computed: {
    id() {
      return this.dataset?.id ?? "";
    },
    title() {
      return this.dataset?.title ?? "";
    },
    topics() {
      return this.dataset?.topics ?? [];
    },
    tags() {
      return this.dataset?.tags ?? [];
    },
    displayTags() {
      return this.tags?.slice(0, 10) ?? [];
    },
    description() {
      return this.dataset?.description ?? "";
    },
    displayDescription() {
      return this.description < 500 ? this.description : this.description.substring(0, 490) + '...';
    },
    owner() {
      return this.dataset?.owner ?? "";
    },
    organization() {
      return this.dataset?.organization ?? "";
    },
    metadataCreated() {
      return this.dataset?.metadataCreated ?? null;
    },
    metadataModified() {
      return this.dataset?.metadataModified ?? null;
    },
    fonte() {
      let key = this.dataset?.sourceFormatDialect ?? "";
      return SOURCE_LIST[key];
    },
    csvHref() {
      return this.links.BINARY ? "" : this.links?.CSV ?? "";
    },
    catalogoDetailUrl() {
      return { name: CATALOGO_DETAIL.name, params: { id: this.id } };
    },
  },
  methods: {
    goToDetail() {
      this.$store.dispatch("setDatasetDetail", {
        datasetDetail: this.dataset,
      });
      this.$router.push(this.catalogoDetailUrl);
    },
  },
  created() {
    this.dataset?.resourceFormatUrls?.forEach((link) => {
      let splitLink = link.split("|");
      this.links[splitLink[0]] = splitLink[1];
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.card-title h2{
  font: normal normal bold 24px/36px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
  cursor: pointer;
  &:hover {
    .launch-icon {
      transform: scale(1.1);
    }
  }
}
.topic-item:before {
  background-color: #e6feff !important;
}
.tag-chip:hover:before {
  opacity: 0;
}
.dataset-description {
  font-size: 16px;
}
.publisher-info {
  font-size: 14px;
}
.body-bold {
  font-weight: 700;
}
.tag-text {
  color: $text-dark;
  font-size: 12px;
}
.csv-icon {
  font-size: 16px;
}
.csv-text {
  color: $text-white;
}
</style>