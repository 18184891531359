<template>
  <v-chip
    v-if="hasValue"
    class="filter-chip mr-2 my-2"
    color="#304796"
    text-color="white"
  >
    {{ value }}
    <v-icon color="#2AB5B9" class="ml-2 close-icon" @click="removeParam"
      >cancel</v-icon
    >
  </v-chip>
</template>

<script>
import { ENTI_DETAIL, CATALOGO } from "../router/routes";
export default {
  name: "FilterChip",
  props: {
    filterType: { type: String, required: true },
    filterValue: { type: String, required: false, default: "" },
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    hasValue() {
      return this.filterValue != "" && this.filterValue != "*";
    },
    value() {
      return this.filterValue.toLowerCase() == "opendata"
        ? "geoportale"
        : this.filterValue;
    },
  },
  methods: {
    removeParam() {
      if (this.filterType == "owners" && this.$route.name == ENTI_DETAIL.name) {
        this.$store.dispatch("clearSearchParams");
        this.$router.push({ name: CATALOGO.name });
      } else {
        let payload = this.searchParams;

        if (this.filterType == "query") {
          payload.query = "";
        } else {
          payload[this.filterType] = [];
        }

        this.$store.dispatch("setSearchParams", {
          searchParams: payload,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-chip {
  border-radius: 10px;
  .close-icon {
    font-size: 18px;
  }
}
</style>
