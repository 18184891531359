<template>
  <v-row class="justify-space-between dataset-header">
    <v-col class="col-12 col-lg-6">
      <p
        class="dataset-title"
        :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
      >
        <span id="dataset-count">{{ datasetCount }}</span> dataset trovati
      </p>
      <p
        class="download-all"
        @click="downloadAll"
        :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
      >
        <v-icon color="#304796" class="download-icon">save_alt</v-icon>
        <span class="download-text ml-1">Scarica tutti</span>
      </p>
    </v-col>
    <v-col class="order-by col-12 col-lg-4 ml-4 ml-lg-0">
      <v-row>
        <div class="order-by-text d-flex align-center justify-end">
          ordina per
        </div>
        <v-select
          class="ml-2 mt-4 order-select"
          v-model="order"
          :items="orders"
          @change="onChange"
          dense
          height="20"
          append-icon="keyboard_arrow_down"
        ></v-select>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { downloadDatasetListCsv } from "../services/api";
import { ORDERS, ORDERS_PARAMS } from "../boot/globals";
export default {
  name: "DatasetResultHeader",
  props: {
    datasetCount: { type: Number, required: false },
  },
  data() {
    return {
      order: ORDERS[0],
      orders: ORDERS,
    };
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
  },
  methods: {
    async downloadAll() {
      if (!this.datasetCount || this.datasetCount == 0) return;

      try {
        let { data } = await downloadDatasetListCsv(this.searchParams);
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:csv;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute(
          "download",
          "search_dataset_CSV_" + Date.now() + ".csv"
        );

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } catch (err) {
        console.log(err);
      }
    },
    onChange() {
      let payload = this.searchParams;
      payload.sorting = ORDERS_PARAMS[this.order];

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });
    },
  },
  mounted() {
    let actualSortingStrategy =
      this.searchParams.sorting[0]?.sortingStrategy ?? null;
    switch (actualSortingStrategy) {
      case "desc":
        this.order = ORDERS[0];
        break;

      case "asc":
        this.order = ORDERS[1];
        break;

      default:
        this.order = ORDERS[2];
        break;
    }
  },
};
</script>

<style lang="scss">
.dataset-header {
  .dataset-title {
    font-weight: 700;
    letter-spacing: 0;
    font-size: 24px;
  }
  .download-all {
    cursor: pointer;
    .download-icon {
      font-size: 18px;
    }
    .download-text {
      text-decoration: underline;
      color: #304796;
      font-size: 16px;
    }
  }
  .order-by {
    .order-by-text {
      font-size: 16px;
    }
    .order-select {
      .v-input__control {
        font-weight: bold;
        max-width: fit-content;
      }
    }
  }
}
</style>