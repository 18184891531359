<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">{{ item.displayValue }}</span>
      </template>
      <span>{{ item.term }}</span>
    </v-tooltip>
    <span v-if="item.frequency" class="ml-1">({{ item.frequency }})</span>
  </div>
</template>

<script>
export default {
  name: "DatasetFIlterItem",
  props: {
    item: { type: Object, required: false },
  },
};
</script>

<style scoped lang="scss">
</style>
