<template>
  <v-autocomplete
    :loading="loading"
    v-model="model"
    :search-input.sync="search"
    :items="items"
    class="mx-4"
    outlined
    single-line
    no-filter
    no-data-text="digita almeno tre caratteri.."
    auto-select-first
    label="Cerca nel catalogo"
    background-color="#ffffff"
  >
    <v-icon
      class="search-icon"
      slot="append"
      color="#29CED3"
      @click="onSearch"
      :disabled="!search || isDisabledSearch"
      >search</v-icon
    >
  </v-autocomplete>
</template>

<script>
import { loadDatasetList } from "../services/api";
import { ENTI_DETAIL, CATALOGO } from "../router/routes";
export default {
  name: "TagItem",
  props: {
    count: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
  },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      model: null,
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    model(val) {
      val && this.onSearch();
    },
  },
  computed: {
    isDisabledSearch() {
      return this.search.length < 3;
    },
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    states() {
      return this.$store.getters["getDatasetList"];
    },
  },
  methods: {
    onSearch() {
      if (this.search.length < 3) return;

      if (
        this.$route.name != CATALOGO.name &&
        this.$route.name != ENTI_DETAIL.name
      ) {
        this.$store.dispatch("clearSearchParams");
      }

      let payload = this.searchParams;
      payload.query = this.model;
      payload.sorting = [];

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });

      if (
        this.$route.name != CATALOGO.name &&
        this.$route.name != ENTI_DETAIL.name
      ) {
        this.$router.push({ name: CATALOGO.name });
      }

      this.model = null;
      this.search = null;
      this.items = [];
    },
    async querySelections(v) {
      this.items = [];
      if (v.length < 3) return [];
      this.loading = true;

      let payload = {
        query: v,
        pageIndex: 0,
        pageSize: 10,
        sorting: [],
        owners: [],
        tags: [],
        topics: [],
        advanced: false,
      };
      let datasetListPromise = loadDatasetList(payload);

      try {
        let { data } = await datasetListPromise;
        this.items.push(v);
        data.datasets?.forEach((element) => {
          this.items.push(element.title);
        });
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.tag-item {
  color: $text-tag;
}
.mobile {
  .tag-item {
    font-size: 8px;
  }
}
</style>