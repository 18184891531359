<template>
  <v-container class="filter-section">
    <v-row
      class="filter-title justify-between"
      @click="
        isOpen = !isOpen;
        displayAll = false;
      "
    >
      <v-col class="text-uppercase pl-0">{{ filterName }}</v-col>
      <v-icon class="filter-icon" v-if="!isOpen">keyboard_arrow_down</v-icon>
      <v-icon class="filter-icon" v-else>keyboard_arrow_up</v-icon>
    </v-row>
    <div v-if="isOpen">
      <div v-if="!isFiltered">
        <div v-if="!displayAll && items.length > 10">
          <v-row
            v-for="item in displayedItems"
            :key="item.term"
            class="filter-item py-2"
            @click="onFilterClick(item.term)"
          >
            <dataset-filter-item :item="item"></dataset-filter-item>
          </v-row>
          <v-row class="filter-item show-all py-2" @click="displayAll = true">
            <span>mostra tutti</span>
          </v-row>
        </div>
        <div v-else>
          <v-row
            v-for="item in orderedItems"
            :key="item.term"
            class="filter-item py-2"
            @click="onFilterClick(item.term)"
          >
            <dataset-filter-item :item="item"></dataset-filter-item>
          </v-row>
          <v-row
            v-if="displayAll && items.length > 10"
            class="filter-item show-all py-2"
            @click="displayAll = false"
          >
            <span>mostra meno</span>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row class="filter-item py-2 active">
          <span>{{ activeItemTerm }}</span>
          <span v-if="activeItemFrequency" class="ml-1"
            >({{ activeItemFrequency }})</span
          >
          <v-spacer></v-spacer>
          <v-icon
            color="#2AB5B9"
            class="mr-2 close-icon"
            @click.stop="removeParam"
            >cancel</v-icon
          >
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { ENTI_DETAIL, CATALOGO } from "../router/routes";
import DatasetFilterItem from "./DatasetFIlterItem";
export default {
  name: "DatasetFilterSection",
  components: {
    DatasetFilterItem,
  },
  props: {
    filterName: { type: String, required: false, default: "" },
    filterType: { type: String, required: false, default: "" },
    items: { type: Array, required: false },
  },
  data() {
    return {
      isOpen: false,
      displayAll: false,
    };
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    orderedItems() {
      let orderedItems = this.sortByName(this.items);
      return orderedItems;
    },
    displayedItems() {
      let orderedItems = this.sortByCount(this.items);
      return orderedItems;
    },
    activeItem() {
      let active = this.orderedItems.filter((r) => {
        return (
          r.term.toLowerCase() ===
          this.searchParams[this.filterType][0].toLowerCase()
        );
      });
      return active[0];
    },
    activeItemTerm() {
      return this.activeItem?.displayValue ?? "";
    },
    activeItemFrequency() {
      return this.activeItem?.frequency ?? null;
    },
    isFiltered() {
      return this.searchParams[this.filterType].length != 0;
    },
  },
  methods: {
    removeParam() {
      if (this.filterType == "owners" && this.$route.name == ENTI_DETAIL.name) {
        this.$store.dispatch("clearSearchParams");
        this.$router.push({ name: CATALOGO.name });
      } else {
        let payload = this.searchParams;
        payload[this.filterType] = [];

        this.$store.dispatch("setSearchParams", {
          searchParams: payload,
        });
      }
    },
    onFilterClick(filter) {
      let payload = this.searchParams;
      payload[this.filterType].push(filter);

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });
    },
    sortByName(items) {
      let result = items.sort((a, b) => {
        a = a.term.toUpperCase();
        b = b.term.toUpperCase();

        return a > b ? 1 : -1;
      });

      return result;
    },
    sortByCount(items) {
      let result = items.sort((a, b) => {
        a = a.frequency;
        b = b.frequency;

        return a < b ? 1 : -1;
      });

      return this.sortByName(result.slice(0, 10));
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.filter-section {
  .filter-title {
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
    font-weight: 700;
    letter-spacing: 0;
    color: #2f4595;
    font-size: 20px;
    .filter-icon {
      font-weight: 700;
      color: #2f4595;
      font-size: 32px;
    }
  }
  .filter-item {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    .close-icon {
      font-size: 18px;
    }
    &.active {
      background-color: #e6feff;
    }
    &:hover {
      background-color: #e6feff;
    }
  }
  .show-all {
    color: #2f4595;
  }
}
</style>
